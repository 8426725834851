import axios from "axios";
import { parseCookies, setCookie, destroyCookie } from "nookies";

var numeral = require("numeral");
const MASTER_URL = process.env.MASTER_URL;
const cookieOptions = {
  path: "/",
  maxAge: 365 * 24 * 60 * 60,
  sameSite: "none",
  secure: true,
};

// 콤마(,) 삭제
// 콤마(,) 삭제
export const remove_comma = (str) => {
  str = String(str);
  return str.replace(",", "");
};

export const check_vat = (price) => {
  let tax_price = Math.floor(price * 0.1);
  return tax_price;
};

export const check_point = (price) => {
  let point = numeral(Math.round((price / 10) * 0.01) * 10).format("0,0");
  return point;
};

function convert_half_number(str) {
  str = str.replace(/０/g, "0");
  str = str.replace(/１/g, "1");
  str = str.replace(/２/g, "2");
  str = str.replace(/３/g, "3");
  str = str.replace(/４/g, "4");
  str = str.replace(/５/g, "5");
  str = str.replace(/６/g, "6");
  str = str.replace(/７/g, "7");
  str = str.replace(/８/g, "8");
  str = str.replace(/９/g, "9");
  return str;
}

// 숫자(가격,수량) 입력 : 세자리단위 콤마(,) 생성
function set_field_comma(str) {
  str.value = parseInt(remove_comma(convert_half_number(str.value)));
  if (str.value == "NaN") {
    str.value = "";
  }
  var n = str.value.match(/\d+/g).join("");
  var reg = /(^[+-]?\d+)(\d{3})/;
  n += "";
  while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");
  str.value = n;
}

export async function SetCookie(name, value) {
  try {
    setCookie(null, name, value, cookieOptions);
  } catch (e) {
    throw new Error("set cookie failed");
  }
}

export async function GetCookie(name) {
  try {
    const values = await parseCookies();
    return values[name];
  } catch (e) {
    throw new Error("get cookie failed");
  }
}

export async function RemoveCookie(name) {
  destroyCookie(null, name, {
    path: "/", // THE KEY IS TO SET THE SAME PATH
  });
}

export function SetToken(accessToken) {
  axios.defaults.headers.Authorization = "Bearer " + accessToken;
  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24);

  SetCookie("token", accessToken);
}

export function HistoryBack() {}

//페이지 강제 이동
export function LocationReplace(url) {
  if (url === "") {
    window.location.replace("/");
  } else {
    window.location.replace(url);
  }
}

//Alert후 페이지 강제 이동
export function AlertLocationReplace(msg, url) {
  alert(msg);
  if (url === "") {
    window.location.replace("/");
  } else {
    window.location.replace(url);
  }
}

export function escapeHtml(text) {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function GoSearch(inputKeyword) {
  if (inputKeyword == "") {
    alert("검색어를 입력하세요");
  } else {
    window.location.href =
      "/search/lists?keyword=" + inputKeyword.startsWith("%")
        ? inputKeyword
        : encodeURIComponent(inputKeyword);
  }
}

export async function GetWether(source, is_hotdeal) {
  const res = await axios.get(
    MASTER_URL + "/api/v1/vendor/partner/delivery_period?maker=" + source
  );
  const data = await res.data;
  if (data.status === 200) {
    return data.data;
  }
}

export async function GetWetherShoppingMall(source, ca_ids) {
  const res = await axios.get(
    MASTER_URL +
      "/api/v1/vendor/partner/delivery_period_shoppingmall?maker=" +
      source +
      "&ca_id=" +
      ca_ids
  );
  const data = await res.data;
  if (data.status === 200) {
    return data.data;
  }
}

export function regExp(str) {
  var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
  //특수문자 검증
  if (reg.test(str)) {
    //특수문자 제거후 리턴
    return str.replace(reg, "");
  } else {
    //특수문자가 없으므로 본래 문자 리턴
    return str;
  }
}

export function after3day() {
  const after3day = new Date();
  after3day.setDate(after3day.getDate() + 3);
  return new Date();
}

export function lifecycle(lc) {
  switch (lc) {
    case "Coming Soon":
    case "New Product":
    case "New Technology":
    case "Preliminary Release":
      return <img src="/images/new.png"></img>;
    case "New at Mouser":
    case "Factory Special Order":
    case "Part Number Change":
    case "Restricted Availability":
    case "Verify Status with Factory":
      return <img src="/images/production.png"></img>;
    case "Not Recommended for New Designs":
    case "End of Life":
      return <img src="/images/eol.png"></img>;
    case "Obsolete":
      return <img src="/images/obsolete.png"></img>;
    default:
      return <img src="/images/production.png"></img>;
  }
}

// iE, firefox 대응
export async function checkIEAccess() {
  if (navigator.userAgent.indexOf("Trident") > 0) {
    window.location.href = `/noie`;
  } else if (/MSIE \d |Trident.*rv:/.test(navigator.userAgent)) {
    window.location.href = `/noie`;
  }
}
